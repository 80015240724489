import React, { useState } from "react";
import "./FileUpload.css";
import ProjectDetailsInput from "./ProjectDetailsInput";
// import FileUploadSingleComponent from "./FileUploadSingleComponent";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InactiveSidebar from "./InactiveSidebar";
import TopNavbar from "./TopNavbar";
import fetchUtil from "./utils/fetchUtil";
import WithNavbar from "./LeftNavbar";
const LoadingSpinner = () => <div className="spinner">Loading...</div>;
const DigitalTwinViewerInsertion = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [isLoading, setIsLoading] = useState(false);

  const [projectImage, setProjectImage] = useState(null);

  const handleImageChange = (e) => {
    setProjectImage(e.target.files[0]);
  };
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(projectDetails);
    const formattedData = {
      ...projectDetails,
      construction_year: projectDetails.construction_year
        ? parseInt(projectDetails.construction_year)
        : null,
      last_modernized: projectDetails.last_modernized
        ? parseInt(projectDetails.last_modernized)
        : null,
      gross_asset_size: projectDetails.gross_asset_size
        ? parseInt(projectDetails.gross_asset_size)
        : null,
      processing: 0,
      customer_id: 1,
    };

    try {
      const projectData = await fetchUtil(
        `${apiRoot}/api/projects/`,
        "POST",
        token,
        formattedData
      );
      const projectID = projectData.id;

      if (projectImage) {
        const formData = new FormData();
        formData.append("image_file", projectImage);
        formData.append("project", projectID);

        const imageUploadData = await fetchUtil(
          `${apiRoot}/api/images/`,
          "POST",
          token,
          formData,
          true
        );
        console.log("Image upload successful", imageUploadData);
      }

      const userProjectAssignmentBody = {
        project: projectID,
        user: userID,
      };
      const userProjectAssignmentData = await fetchUtil(
        `${apiRoot}/api/project_assignment/`,
        "POST",
        token,
        userProjectAssignmentBody
      );
      console.log("Second API call successful", userProjectAssignmentData);
      setIsLoading(false);
      navigate("/user-projects");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
    }
  };
  const [projectDetails, setProjectDetails] = useState({
    construction_year: "",
    name: "",
    type: "office",
    last_modernized: "",
    gross_asset_size: "",
    descriptive_name: "",
  });

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (
      name === "construction_year" ||
      name === "last_modernized" ||
      name === "gross_asset_size"
    ) {
      formattedValue = value.replace(/\D/g, "");
    }
    console.log(value);
    setProjectDetails({ ...projectDetails, [name]: value });

    // setProjectDetails({ ...projectDetails, [name]: formattedValue });
  };

  const projectFields = [
    { label: "Project Name", name: "name", className: "narrow" },
    {
      label: "Construction year",
      name: "construction_year",
      className: "narrow",
    },
    {
      label: "Modernization year",
      name: "last_modernized",
      className: "narrow",
    },
    {
      label: "Type",
      name: "type",
      type: "select",
      className: "narrow",
      options: ["office", "commercial", "public"],
    },
    {
      label: "Gross Asset Size",
      name: "gross_asset_size",
      className: "narrow",
    },
    { label: "Asset ID", name: "asset_id", className: "narrow" },
    { label: "Location", name: "location", className: "narrow" },
    { label: "Address", name: "address", className: "wide" },
    { label: "DescriptiveName", name: "descriptive_name", className: "wide" },
  ];

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      {/* <Sidebar /> */}
      {/* <InactiveSidebar /> */}

      {/* <Sidebar /> */}
      <div className="upload-container">
        {isLoading && <LoadingSpinner />}
        <h2>Digital Twin Viewer Insertion</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <h1>set project generic information</h1>
          </div>

          <div className="form-grid">
            {projectFields.map((field) =>
              field.type === "select" ? (
                <ProjectDetailsInput
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={projectDetails[field.name]}
                  onChange={handleDetailChange}
                  className={field.className}
                  type={field.type}
                  options={field.options}
                />
              ) : (
                <ProjectDetailsInput
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={projectDetails[field.name]}
                  onChange={handleDetailChange}
                  className={field.className}
                />
              )
            )}
            {/* <input
              type="file"
              name="projectImage"
              onChange={handleImageChange}
            /> */}

            <div className="file-upload">
              <label>upload your image file here</label>
              <div className="file-upload-wrapper">
                <input
                  type="file"
                  name="projectImage"
                  onChange={handleImageChange}
                />
                <label className="file-upload-label">Choose File</label>
                <span className="file-name"></span>
              </div>
            </div>
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DigitalTwinViewerInsertion;
