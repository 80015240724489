import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./AssignToProject.css";
import Sidebar from "./Sidebar";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";

const AssignToProject = () => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const projectID = localStorage.getItem("projectID");
  const userID = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  console.log("API Root URL:", apiRootSecond);
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  console.log(token);

  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiRoot}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const formattedUsers = data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      console.log(response);
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // const fetchProjects = async () => {
  //   try {
  //     const response = await fetch(`${apiRootSecond}/api/projects/`, {
  //       // const response = await fetch(`${apiRoot}/api/projects?limit=1000`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const data = await response.json();
  //     const formattedProjects = data.map((project) => ({
  //       // const formattedProjects = data.map((project) => ({
  //       value: project.id,
  //       label: project.descriptive_name,
  //     }));
  //     setProjects(formattedProjects);
  //   } catch (error) {
  //     console.error("Error fetching projects:", error);
  //   }
  // };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${apiRootSecond}/api/projects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const formattedProjects = data.map((project) => ({
        value: project.id,
        label: project.name,
      }));

      setProjects(formattedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const assignUsersToProjectsBulk = async (userIds, projectIds, authToken) => {
    const endpoint = `${apiRootSecond}/api/project_assignment/`;

    let bulkAssignments = [];
    userIds.forEach((userId) => {
      projectIds.forEach((projectId) => {
        bulkAssignments.push({
          user: userId,
          project: projectId,
        });
      });
    });
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(bulkAssignments),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await response.json();
      return true;
    } catch (error) {
      console.error("Failed to perform bulk assignment:", error);
      return false;
    }
  };

  const handleAssign = async () => {
    const userIds = selectedUser.map((user) => user.value);
    const projectIds = selectedProject.map((project) => project.value);
    const authToken = token;

    try {
      const isSuccess = await assignUsersToProjectsBulk(
        userIds,
        projectIds,
        authToken
      );

      if (isSuccess) {
        alert("Users successfully assigned to projects.");
        setSelectedUser([]);
        setSelectedProject([]);
      } else {
        alert("Failed to assign users to projects.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An error occurred during the assignment.");
    }
  };

  return (
    <>
      <WithNavbar />
      <TopNavbar />

      <div className="assign-to-project-container">
        <h2 className="assign-to-project-heading">Assign User to Project</h2>
        <div className="assign-to-project-dropdown">
          <label className="assign-to-project-label">User:</label>
          <Select
            options={users}
            value={selectedUser}
            onChange={setSelectedUser}
            isMulti
            placeholder="Select a user"
          />
        </div>
        <div className="assign-to-project-dropdown">
          <label className="assign-to-project-label">Project:</label>
          <Select
            options={projects}
            value={selectedProject}
            onChange={setSelectedProject}
            isMulti
            placeholder="Select a project"
          />
        </div>
        <button className="assign-to-project-button" onClick={handleAssign}>
          Assign
        </button>
      </div>
    </>
  );
};

export default AssignToProject;
