import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProjectDetailPage.css";
import "./FileUpload.css";
import ProjectDetailsInput from "./ProjectDetailsInput";
import FileUploadSingleComponent from "./FileUploadSingleComponent";
import fetchUtil from "./utils/fetchUtil";
import { useRef } from "react";
import TopNavbar from "./TopNavbar";
import WithNavbar from "./LeftNavbar";
import { useNavigate } from "react-router-dom";
const ProjectDetailPage = () => {
  let navigate = useNavigate();
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const fileInputRef = useRef(null);

  const [editingImage, setEditingImage] = useState(false);

  const { projectId } = useParams();
  const [project, setProject] = useState({
    name: "",
    construction_year: "",
  });

  // const [project, setProject] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${apiRoot}/api/projects/${projectID}/get_project_details/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setProject(data);
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async (file, apiUrl) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetchUtil(
        `${apiRoot}/api/images/`,
        "POST",
        localStorage.getItem("token"),
        formData,
        true
      );
      if (response.status === 200 || response.status === 201) {
        alert("Project image updated successfully!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const typeMapping = {
      office: 1,
      commercial: 2,
      public: 3,
    };

    try {
      const updatedProjectData = {
        name: project.name,
        construction_year: project.construction_year,
        last_modernized: project.last_modernized,
        type: project.type,
        // type: typeMapping[project.type] || project.type,
        gross_asset_size: project.gross_asset_size,
        area: project.gross_asset_size,
        address: project.address,
        descriptive_name: project.descriptive_name,
      };
      console.log(updatedProjectData);
      console.log(`${apiRoot}/api/projects/${projectID}/`);
      console.log(token);

      const response = await fetchUtil(
        `${apiRoot}/api/projects/${projectID}/`,
        "PUT",
        localStorage.getItem("token"),
        updatedProjectData
      );
      alert("Project details updated successfully!");
      if (response.status === 200 || response.status === 201) {
        alert("Project details updated successfully!");
      }
    } catch (error) {
      console.error("Error updating project details:", error);
    }
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  const projectFields = [
    {
      label: "Project Name",
      name: "name",
      className: "narrow",
      type: "text",
    },
    {
      label: "Construction Year",
      name: "construction_year",
      className: "narrow",
      type: "text",
    },
    {
      label: "Gross Asset Size",
      name: "gross_asset_size",
      className: "narrow",
      type: "text",
    },
    {
      label: "Modernization Year",
      name: "last_modernized",
      className: "narrow",
      type: "text",
    },
    {
      label: "type",
      name: "type",
      className: "narrow",
      type: "select",
      options: ["office", "commercial", "public"],
    },
    {
      label: "Address",
      name: "address",
      className: "wide",
      type: "text",
    },
    {
      label: "DescriptiveName",
      name: "descriptive_name",
      className: "wide",
      type: "text",
    },
  ];
  function returnBackButton() {
    const projectId = localStorage.getItem("projectID");
    navigate(`/project-edit/${projectId}`);
  }

  return (
    <form onSubmit={handleSubmit}>
      <WithNavbar />
      <TopNavbar />
      <div className="project-detail-container">
        <h1>{project.name}</h1>
        {/* <img src={project.image_url_value} alt={project.name} /> */}
        <div className="image-container">
          <img src={project.image_url_value} alt={project.name} />
          <div
            className="camera-icon-overlay"
            onClick={() => fileInputRef.current.click()}
          >
            📷{" "}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e, setFile1)}
          />
          {file1 && (
            <button
              className="upload-image-button"
              onClick={() =>
                handleUpload(file1, "your_image_upload_api_endpoint")
              }
            >
              Upload Image
            </button>
          )}
        </div>

        <div className="form-grid">
          {projectFields.map((field) =>
            field.type === "select" ? (
              <ProjectDetailsInput
                key={field.name}
                label={field.label}
                name={field.name}
                value={project[field.name] || ""}
                onChange={handleChange}
                className={field.className}
                options={field.type === "select" ? field.options : undefined}
                type={field.type}
              />
            ) : (
              <ProjectDetailsInput
                key={field.name}
                label={field.label}
                name={field.name}
                value={project[field.name] || ""}
                onChange={handleChange}
                className={field.className}
                // options={field.type === "select" ? field.options : undefined}
                // type={field.type}
              />
            )
          )}
        </div>
        {/* <div>
          <button type="submit">Save Changes</button>
          <button type="submit" onClick={returnBackButton}>
            Back to Project Configuration
          </button>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <button type="submit">Save Changes</button>
          <button type="submit" onClick={returnBackButton}>
            Back to Project Configuration
          </button>
          {/* <button type="submit">Save Changes</button> */}
        </div>
      </div>
    </form>
  );
};
export default ProjectDetailPage;
